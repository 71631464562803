<template>
	
	<div class="grid">
		<Toast />
		<!-- <template v-if="ingresoCostumer==false">
			<div class="col-12 lg:col-12">
			<center><h2>Funcionarios</h2></center>
			</div>
			<template v-for="(customer,index) in customers" :key="index">
				<div class="col-12 lg:col-6">
					<div class="card height-90 flex flex-column m-3">
						<div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
							<img src="layout/images/widgets/feature-onboarding.svg" alt="feature-faq">
						</div>
						<div class="flex flex-column align-items-center mt-3">
							<h5 class="mb-2">{{customer.customer.name}}</h5>
						</div>
						<div class="flex justify-content-between align-items-center pt-3 mt-auto">
							<small class="muted-text"></small>
							<template v-if="customer.status==1">
								<Button label="Ingresar" @click="abrirCustomer(customer,index)"></Button>
							</template>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template v-else> -->
			<div class="col-12 lg:col-12">
				<!-- <center><h2 class="alignment-center">{{ infoCostumer.customer.name }}</h2></center> -->
			</div>
			<div class="col-12 lg:col-12">
			<Toolbar class="mb-4">
				<template v-slot:start>
					<div class="my-2">
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						<!-- <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" /> -->
					</div>
					<div class="mt-3 ml-5">
						<center><h3>Funcionarios</h3></center>
						<!-- <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" /> -->
					</div>
				</template>
				<!-- <template v-slot:end>
					<Button label="Volver" icon="pi pi-chevron-left" class="mr-2 mb-2" @click="salirCustomer"></Button><br>
				</template> -->
			</Toolbar>
			<template v-if="loader==true">
				<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
			</template>
			<DataTable ref="dt" :value="funcionarios" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} customers" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<!-- <h5 class="m-0">Funcionarios:</h5> -->
							<!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                            </span> -->
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="num_identificacion" header="Núm Identificación" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Núm Identificación</span>
							{{slotProps.data.num_identificacion}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Nombre</span>
							{{slotProps.data.nombre+' '+slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="razon_social" header="Razón Social" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Razón Social</span>
							{{slotProps.data.razon_social}}
						</template>
					</Column>
					<Column field="direccion" header="Dirección" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Direccion</span>
							{{slotProps.data.direccion}}
						</template>
					</Column>
					<Column field="estado" header="Estado" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Estado</span>
							<template v-if="slotProps.data.estado==1">
								{{'Activo'}}
							</template>
							<template v-else>
								{{'Inactivo'}}
							</template>
						</template>
					</Column>
					<Column field="acciones" header="Acciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<div>
					<Paginator :v-model:first="pagination.from" :rows="pagination.per_page" :rowsPerPageOptions="[10]" :totalRecords="pagination.total" @page="onChangePage($event)"></Paginator>
				</div>

				<Dialog v-model:visible="productDialog" :style="{width: '550px'}" header="Funcionario" :modal="true" class="p-fluid">
					<!-- <img :src="'demo/images/product/' + product.image" :alt="product.image" v-if="product.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" /> -->
					<div class="field">
						<label for="tipo_documento">Tipo de documento</label>
						<Dropdown v-model="selectipo_documento" :options="tipos_documentos" placeholder="Seleccionar tipo de documento" />
						<small class="p-invalid p-error" v-if="!selectipo_documento">Tipo de documento es requerido.</small>
					</div>
					<div class="field">
						<label for="name">Número de documento</label>
						<InputText type="number" id="num_documento" v-model.trim="frmFuncionario.num_documento" required="true" autofocus :class="{'p-invalid': submitted && !frmFuncionario.codigo}" />
						<small class="p-invalid p-error" v-if="!frmFuncionario.num_documento">Número de documento es requerido.</small>
					</div>
					<div class="field">
						<label class="mb-2">Nombre</label>	
						<div class="formgrid grid">
							
							<div class="field-radiobutton col-6">
								<br>
								<InputText id="nombre" v-model.trim="frmFuncionario.nombre" placeholder="Nombres" required="true" autofocus :class="{'p-invalid': submitted && !frmFuncionario.nombre}" />
								<small class="p-invalid p-error" v-if="!frmFuncionario.nombre">Nombres es requerido.</small>

								<!-- <label for="category1">Activo</label> -->
							</div>
							<div class="field-radiobutton col-6">
								<InputText id="apellido" v-model.trim="frmFuncionario.apellido" placeholder="Apellidos" required="true" autofocus :class="{'p-invalid': submitted && !frmFuncionario.apellido}" />
								<small class="p-invalid p-error" v-if="!frmFuncionario.apellido">Apellidos es requerido.</small>
							</div>
						</div>
					</div>
					<div class="field">
						<label for="correo">Correo electrónico</label>
						<InputText type="email" id="email" v-model.trim="frmFuncionario.correo" required="true" autofocus :class="{'p-invalid': submitted && !frmFuncionario.correo}" />
						<small class="p-invalid p-error" v-if="!frmFuncionario.correo">Correo electrónico es requerido.</small>
					</div>

					<div class="field">
						<label for="inventoryStatus" class="mb-3">Seleccionar empresa</label>
						<Dropdown v-model="selectgenerador" :options="generadores" optionLabel="razon_social" placeholder="Seleccionar una empresa" />
						<small class="p-invalid p-error" v-if="!selectgenerador">Selección de empresa es requerido.</small>
					</div>
					<div class="field">
						<label for="cel">Número de celular</label>
						<InputText id="cel" v-model.trim="frmFuncionario.cel" required="true" autofocus />
						<!-- <small class="p-invalid p-error" v-if="!frmFuncionario.cel">Número de celular es requerido.</small> -->
					</div>
					<div class="field">
						<label for="correo">Dirección</label>
						<InputText id="direccion" v-model.trim="frmFuncionario.direccion" required="true" autofocus :class="{'p-invalid': submitted && !frmFuncionario.direccion}" />
						<small class="p-invalid p-error" v-if="!frmFuncionario.direccion">Dirección es requerida.</small>
					</div>
					<div class="field">
						<label class="mb-3">Estado</label>
						<div class="formgrid grid">
							<div class="field-radiobutton col-6">
								<RadioButton value="1" v-model="frmFuncionario.estado" />
								<label for="category1">Activo</label>
							</div>
							<div class="field-radiobutton col-6">
								<RadioButton value="0" v-model="frmFuncionario.estado" />
								<label for="category1">Inactivo</label>
							</div>
						</div>
						<small class="p-invalid p-error" v-if="!frmFuncionario.estado">Estado es requerido.</small>

					</div>
					<div class="field">
						<label for="description">Nota</label><br>
						<Textarea id="description" v-model="frmFuncionario.nota" required="true" rows="3" cols="53" autofocus />
						<!-- <small class="p-invalid p-error" v-if="!frmFuncionario.nota">Descripción es requerido.</small> -->
					</div>
					<div class="field">
						<label for="description">Imagen</label><br>
						<!-- <FileUpload name="demo[]" url="./upload.php" @upload="onUpload" :multiple="true" accept="image/*" :maxFileSize="1000000"/> -->
						<template v-if="!frmFuncionario.url_img">
							<!-- <FileUpload v-model="frmFuncionario.url_img" name="demo[]" url="./upload.php" @click="onUpload" :multiple="true" accept="image/*" :maxFileSize="1000000"/> -->
							<FileUpload mode="basic" :customUpload="true" name="demo[]" url="./upload.php" @click="onUpload" @upload="onUpload" accept="image/*" :maxFileSize="1000000" />

						</template>
						<template v-else>
							<div class="flex justify-content-center">
								<Image :src="frmFuncionario.url_img" alt="Image" width="250" preview />
							</div>
							<!-- <Button id="buttoneditar" label="Editar" icon="pi pi-check" class="p-button-text" @click="editarfuncionarios" /> -->
						</template>
					</div>
					
					<template #footer>
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></center>
						</template>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<template v-if="!frmFuncionario.id_funcionarios">
						<Button id="buttoncrear" label="Crear" icon="pi pi-check" class="p-button-text" @click="crearfuncionarios" />
						</template>
						<template v-else>
							<Button id="buttoneditar" label="Editar" icon="pi pi-check" class="p-button-text" @click="editarfuncionarios" />
						</template>
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro de que quieres eliminar este registro <b></b>?</span>
					</div>
					<div class="flex align-items-center justify-content-center">
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button id="buttoneliminar" label="Eliminar" icon="pi pi-check" class="p-button-text" @click="deleteFuncionario" />
					</template>
				</Dialog>
			</div>
		<!-- </template> -->
	</div>
</template>

<script>
// import axios from 'axios'
import {FilterMatchMode} from 'primevue/api';

export default {
	data() {
		return {
			message: [],
			customers: [],
			username:null,
			email:null,
			count: 0,
			display: false,
			loader: false,
			frmFuncionario: {
				id_funcionarios: '',
				tipo_documento: '',
				num_documento: '',
				nombre: '',
				apellido: '',
				correo: '',
				cel: '',
				direccion: '',
				nota: '',
				url_img: '',
				id_customer: '',
				id_generador: '',
			},
			selectgenerador: null,
			generadores: [],
			ingresoCostumer: false,
			infoCostumer: [],
			funcionarios: [],
			products: null,
			pagination: {
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			},
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			selectipo_documento: null,
			tipos_documentos: ['Registro civil de nacimiento',
						'Tarjeta de identidad ',
						'Cédula de ciudadanía',
						'Tarjeta de extranjería',
						'Cédula de extranjería',
						'NIT',
						'Pasaporte'],
			filters: {},
			submitted: false
		}
	},
	components:{
        
    },
	mounted(){
		this.abrirCustomer();
	},
	methods:{
		abrirCustomer() {
			const cookieName = 'IdCustomer';
			const cookieUserRol = 'IdUsuarioRol';
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			});
			var id_customer = cookie[cookieName];
			var id_user_rol = cookie[cookieUserRol];
			
			// this.loadDataTable(id_customer);
			this.loadDataTable1(1,id_customer);
			this.loadData(id_user_rol);
			this.ingresoCostumer = true;
			// this.$toast.add({severity:'success', summary: 'Success Message', detail:'Message sent', life: 3000});
		},
		onChangePage(page) {
			console.log(page);
			console.log(page.page+1);
				const cookieName = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				
				const id_customer = cookie[cookieName];
				console.log(id_customer);
				this.loadDataTable1((page.page+1),id_customer);
		},
		clearPaginator(){
			this.pagination.current_page = 0;
			this.pagination.from = 0;
			this.pagination.last_page = 0;
			this.pagination.per_page = 0;
			this.pagination.to = 0;
			this.pagination.total = 0;
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
		nombregenerador({generador}){
            return generador.razon_social;
        },
		onUpload(event) {
			console.log(event.path[0].innerText);
			if(event.path[0].innerText!='Choose'){
				this.frmFuncionario.url_img = event.path[0].innerText;
			}
			
			this.$toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000});
		},
		crearfuncionarios(){
			var _that = this;
			this.loader = true;
			this.submitted = false;
			console.log(this.frmFuncionario);
			if(this.frmFuncionario.estado==''){
				this.frmFuncionario.estado = 0;
			}
			if(this.frmFuncionario.num_documento=='' || this.selectipo_documento==null || !this.frmFuncionario.nombre || !this.frmFuncionario.apellido || !this.frmFuncionario.correo || !this.frmFuncionario.direccion || this.selectgenerador==null){
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor ingresar todos los campos', life: 4000});
				this.loader = false;
				this.submitted = true;
			}else{
				var buttoncrear = document.getElementById('buttoncrear');
				buttoncrear.setAttribute("disabled", "");
				// this.$toast.add({severity:'success', summary: 'Falta información', detail:'Favor ingresar todos los campos', life: 4000});
				// this.frmFuncionario.id_customer = this.infoCostumer.id_customer;
				const cookieName = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				const id_customer = cookie[cookieName];
				// this.frmCostos.id_generador = this.selectgenerador.id_generador;
				this.frmFuncionario.id_customer = id_customer;
				this.frmFuncionario.id_generador = this.selectgenerador.id;
				this.frmFuncionario.tipo_documento = this.selectipo_documento;
				console.log(this.frmFuncionario);

				// axios.post('/crear_funcionarios', {frmFuncionario: this.frmFuncionario}).then(response => {
				// 	console.log(response.data.data);
				// 	// _that.loadDataTable(_that.frmFuncionario.id_customer);
				// 	_that.clearPaginator();
				// 	_that.loadDataTable1(1,_that.frmFuncionario.id_customer);
				// 	_that.hideDialog();
				// 	_that.$toast.add({severity:'success', summary: 'Creación exitosa', detail: response.data.data, life: 4000});
				// 	// _that.loader = false;
				// }).catch(function (error) {
                //     if (error.response) {
				// 		// _that.loader = false;
				// 		console.log(error.response);
				// 		_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 4000});
				// 		buttoncrear.removeAttribute('disabled');
				// 	}
				// });

				var url = '/crear_funcionarios';
				window.api.call('post', url, {frmFuncionario: this.frmFuncionario}).then( function(response) {
					if(response.status == 200){
						console.log(response.data.data);
						// _that.loadDataTable(_that.frmFuncionario.id_customer);
						_that.clearPaginator();
						_that.loadDataTable1(1,_that.frmFuncionario.id_customer);
						_that.hideDialog();
						_that.$toast.add({severity:'success', summary: 'Creación exitosa', detail: response.data.data, life: 4000});
					}
				}).catch(function (error) {
                    if (error.response) {
						// _that.loader = false;
						console.log(error.response);
						_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 4000});
						buttoncrear.removeAttribute('disabled');
					}
				});
			}
		},
		editarfuncionarios(){
			var _that = this;
			this.loader = true;
			this.submitted = false;
			if(this.frmFuncionario.num_documento=='' || this.selectipo_documento==null || !this.frmFuncionario.nombre || !this.frmFuncionario.apellido || !this.frmFuncionario.correo || !this.frmFuncionario.direccion || this.selectgenerador==null){
				// console.log(this.frmCostos);
				this.loader = false;
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor ingresar todos los campos', life: 4000});
				this.submitted = true;
			}else{
				this.frmFuncionario.tipo_documento = this.selectipo_documento;
				this.frmFuncionario.id_generador = this.selectgenerador.id;
				var buttoneditar1 = document.getElementById('buttoneditar');
				buttoneditar1.setAttribute("disabled", "");
				// axios.post('/editar_funcionarios', {frmFuncionario: this.frmFuncionario}).then(response => {
				// 	console.log(response.data.data);
				// 	// _that.loadDataTable(_that.frmFuncionario.id_customer);
				// 	_that.clearPaginator();
				// 	_that.loadDataTable1(1,_that.frmFuncionario.id_customer);
				// 	_that.hideDialog();
				// 	_that.$toast.add({severity:'success', summary: 'Edición exitosa', life: 4000});
				// 	// _that.loader = false;
				// 	buttoneditar1.removeAttribute('disabled');
				// }).catch(function (error) {
                //     if (error.response) {
				// 		buttoneditar1.removeAttribute('disabled');
				// 		_that.loader = false;
				// 		console.log(error.response);
				// 		_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 4000});
				// 	}
				// });

				var url = '/editar_funcionarios';
				window.api.call('post', url, {frmFuncionario: this.frmFuncionario}).then( function(response) {
					if(response.status == 200){
						console.log(response.data.data);
						_that.clearPaginator();
						_that.loadDataTable1(1,_that.frmFuncionario.id_customer);
						_that.hideDialog();
						_that.$toast.add({severity:'success', summary: 'Edición exitosa', life: 4000});
						buttoneditar1.removeAttribute('disabled');
					}
				}).catch(function (error) {
                    if (error.response) {
						buttoneditar1.removeAttribute('disabled');
						_that.loader = false;
						console.log(error.response);
						_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 4000});
					}
				});
			}
		},
		deleteFuncionario(){
			var _that = this;
			var buttoneliminar = document.getElementById('buttoneliminar');
			buttoneliminar.setAttribute("disabled", "");
			this.loader = true;
			// axios.delete('eliminar_funcionarios/' + this.frmFuncionario.id_costos)
			// .then(response => {
			// 	// this.result.splice(id, 1)
			// 	// _that.loadDataTable(_that.frmFuncionario.id_customer);
			// 	_that.clearPaginator();
			// 	_that.loadDataTable1(1,_that.frmFuncionario.id_customer);
			// 	console.log(response);
			// 	// _that.loader = false;
			// 	_that.clearFrmFuncionario();
			// 	_that.deleteProductDialog=false;
			// 	_that.$toast.add({severity:'success', summary: 'Eliminación exitosa', life: 4000});
			// }).catch(error => {
			// 	console.log(error);
			// 	buttoneliminar.removeAttribute('disabled');

			// 	_that.loader = false;
			// 	_that.clearPaginator();
			// 	_that.loadDataTable1(1,_that.frmFuncionario.id_customer);
			// 	_that.clearFrmFuncionario();
			// });

			var url = 'eliminar_funcionarios/' + this.frmFuncionario.id_costos;
			window.api.call('delete', url).then( function(response) {
				if(response.status == 200){
					_that.clearPaginator();
					_that.loadDataTable1(1,_that.frmFuncionario.id_customer);
					console.log(response);
					_that.clearFrmFuncionario();
					_that.deleteProductDialog=false;
					_that.$toast.add({severity:'success', summary: 'Eliminación exitosa', life: 4000});
				}
			}).catch(error => {
				console.log(error);
				buttoneliminar.removeAttribute('disabled');
				_that.loader = false;
				_that.clearPaginator();
				_that.loadDataTable1(1,_that.frmFuncionario.id_customer);
				_that.clearFrmFuncionario();
			});
		},
		loadData(id_user_rol){
			this.generadores = [];
			// var email = localStorage.getItem('Email');
			// axios.post('/cargar_generadores', {usuario_rol: id_user_rol}).then(response => {
			// 	this.generadores = response.data.data;
			// 	console.log(response.data.data);

			// }).catch(function (error) {
			// 	console.log(error);
			// });
			var _that = this;
			var url = '/cargar_generadores';
			window.api.call('post', url, {usuario_rol: id_user_rol}).then( function(response) {
				if(response.status == 200){
					// _that.generadores = response.data.data;
					_that.generadores = response.data.generadores;
				console.log(response.data.data);
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		loadDataTable1(page, id_customer){
			this.funcionarios = [];
			this.loader = true;
			var _that = this;
			// var email = localStorage.getItem('Email');
			// axios.post('/cargar_funcionarios_table?&page='+page, {id_customer: id_customer}).then(response => {
			// 	_that.funcionarios = response.data.data.data;
			// 	_that.pagination = response.data.data;
			// 	console.log(response.data.data);
			// 	_that.loader = false;
			// }).catch(function (error) {
			// 	console.log(error);
			// 	_that.loader = false;
			// });

			var url = '/cargar_funcionarios_table?&page='+page;
			window.api.call('post', url, {id_customer: id_customer}).then( function(response) {
				if(response.status == 200){
					_that.funcionarios = response.data.data.data;
					_that.pagination = response.data.data;
					console.log(response.data.data);
					_that.loader = false;
				}
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		clearFrmFuncionario(){
			// this.frmFuncionario.codigo = '';
			// this.frmFuncionario.id_costos = '';
			// this.frmFuncionario.descripcion = '';
			// this.frmFuncionario.estado = '';
			// this.frmFuncionario.id_customer = '';
			// this.frmFuncionario.id_generador = '';
			this.selectgenerador = null;

			this.frmFuncionario.tipo_documento = '';
			this.frmFuncionario.id_funcionarios = '';
			this.frmFuncionario.nombre = '';
			this.frmFuncionario.correo = '';
			this.frmFuncionario.apellido = '';
			this.frmFuncionario.nota ='';
			this.frmFuncionario.num_documento = '';
			this.frmFuncionario.cel = '';
			this.frmFuncionario.direccion = '';
			this.selectipo_documento = null;
			this.frmFuncionario.id_customer = '';
			this.frmFuncionario.id_generador = '';
			this.frmFuncionario.estado = '';
			this.frmFuncionario.url_img = '';
		},
		openNew() {
			this.product = {};
			this.submitted = false;
			this.productDialog = true;
			this.clearFrmFuncionario();
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		editProduct(product) {
			console.log(product);
			this.frmFuncionario.tipo_documento = product.tipo_documento;
			this.frmFuncionario.id_funcionarios = product.id;
			this.frmFuncionario.nombre = product.nombre;
			this.frmFuncionario.correo = product.correo;
			this.frmFuncionario.apellido = product.apellido;
			this.frmFuncionario.nota = product.nota;
			this.frmFuncionario.num_documento = product.num_identificacion;
			this.frmFuncionario.cel = product.celular;
			this.frmFuncionario.direccion = product.direccion;
			this.selectipo_documento = product.tipo_identificacion;
			this.frmFuncionario.id_customer = product.id_customer;
			this.frmFuncionario.id_generador = product.id_generador;
			this.frmFuncionario.url_img = product.url_img;
			this.frmFuncionario.estado = product.estado.toString();

			var gene = this.generadores.find((usuario) => usuario.id == product.id_generador);
            this.selectgenerador = gene;
			console.log(this.selectgenerador);
			this.product = {...product};
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			// this.product = product;
			this.frmFuncionario.codigo = product.codigo;
			this.frmFuncionario.id_costos = product.id;
			this.frmFuncionario.descripcion = product.descripcion;
			this.frmFuncionario.id_customer = product.id_customer;
			this.frmFuncionario.id_generador = product.id_generador;
			this.frmFuncionario.estado = product.estado.toString();
			this.deleteProductDialog = true;
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>
<style scoped lang="scss">
// @import '../assets/demo/styles/badges.scss';
</style>
